import { get, post } from './http'

export const profile = () => {
  const url = '/profile'
  return get(url)
}

export const profileDetail = () => {
  const url = '/profile/detail'
  return get(url)
}

export const profileUpdate = (data) => {
  const url = '/profile/update'
  return post(url, data)
}

export const uploadAvatar = (data) => {
  const url = '/profile/avatar'
  return post(url, data)
}

export const sendCode = (data) => {
  const url = '/auth/sms_code/change_new'
  return post(url, data)
}

export const changePhone = (data) => {
  const url = '/profile/phone'
  return post(url, data)
}

export const changePassword = (data) => {
  const url = '/profile/change_pass'
  return post(url, data)
}

export const feedbackList = () => {
  const url = '/feedback/my'
  return get(url)
}

export const submitSuggest = (data) => {
  const url = '/feedback'
  return post(url, data)
}

export const myTask = (params) => {
  const url = '/task/my'
  return get(url, params)
}

export const getAward = (params) => {
  const url = '/task/get'
  return get(url, params)
}
